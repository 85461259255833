export const PracticeTiles = [
  {
    name: "Executive Coaching",
    icon: "executive.png",
    coaches: ["Chris Whitehead"],
  },
  {
    name: "Business Coaching",
    icon: "business.png",
    coaches: [
      "Chris Whitehead",
      // 'Nick Mather',
    ],
  },
  // {
  //   name: 'Education',
  //   icon: 'education.png',
  //   coaches: [
  //     'John Elliott',
  //   ],
  // },
  {
    name: "Public Service",
    icon: "civil.png",
    coaches: ["Nick Warren"],
  },
];

export const PracticeGridCaptions = [
  {
    name: "Executive Coaching",
    link: "executive-coaching",
    icon: "executive-01.png",
    caption: `We design and implement coaching interventions that support high
    performance in both leadership
              and team roles and help avoid burn out.`,
  },
  {
    name: "Business Coaching",
    link: "business-coaching",
    icon: "business-01.png",
    caption: `Evidence shows that proactive interventions have a significant
    impact on reducing staff absence and
              increasing productivity, motivation and success.`,
  },
  {
    name: "Public Service Coaching",
    link: "public-service-coaching",
    icon: "civil-01.png",
    caption: `We design and implement coaching interventions that support high
    performance in both leadership
              and team roles and help avoid burn out. `,
  },
];
