import React, { Component } from 'react';
import styled from 'styled-components';

import PageWrapper from '../components/PageWrapper';
import Blogs from '../components/blog/Blogs';
import Tagfilters from '../components/blog/Tagfilters';
import { Flex } from '../styles/blocks';

import { DATA } from '../constants/BlogData';

const Img = styled.img`
  display: block;
  width: 95%;
  max-width: 600px;
  margin: 100px 0 50px;
`;

class BlogContainer extends Component {

  state = {
    blogs: DATA,
    tags: [],
  }

  toggleTag = (tag) => {
    if (!this.state.tags.includes(tag)) {
      this.setState({ tags: [...this.state.tags, tag] });
    } else {
      this.setState({
        tags: [
          ...this.state.tags.slice(0, this.state.tags.indexOf(tag)),
          ...this.state.tags.slice(this.state.tags.indexOf(tag) + 1)
        ]
      });
    }
  }

  render() {

    const { blogs, tags } = this.state;

    return (
      <PageWrapper>
        <Flex centreall col>
          <Img src='/images/home/library dark.svg' />
        </Flex>
        <Tagfilters activeTags={tags} toggleTag={this.toggleTag} />
        <Blogs blogs={blogs} tags={tags}/>
      </PageWrapper>
    );
  }
}

export default BlogContainer;
