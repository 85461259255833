import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HomeContainer from '../containers/HomeContainer';
import BlogContainer from '../containers/BlogContainer';
import Believe from '../components/Believe';
import Coaches from '../components/Coaches';
import Ethics from '../components/Ethics';
import MentorCoaching from '../components/MentorCoaching';
import CoachingRetreats from '../components/CoachingRetreats';
import Practices from '../components/Practices';
import Contact from '../components/Contact';
import NotFound from '../components/NotFound';
import Testimonials from '../components/testimonials';
import Footer from '../components/Footer';

const Routes = (props) =>
  <div>
    <Switch>
      <Route exact path='/' component={HomeContainer} />
      <Route path='/we-believe' component={Believe} />
      <Route path='/testimonials' component={Testimonials} />
      <Route path='/our-coaches' component={Coaches} />
      <Route path='/our-ethics' component={Ethics} />
      <Route path='/our-practices' component={Practices} />
      <Route path='/mentor-coaching' component={MentorCoaching} />
      <Route path='/coaching-retreats' component={CoachingRetreats} />
      <Route path='/contact-us' component={Contact} />
      <Route path='/library' component={BlogContainer} />
      <Route component={NotFound} />
    </Switch>
    <Footer showNewsletter={props.toggleModal}/>
  </div>;

export default Routes;
