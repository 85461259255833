import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

import { _center, _float, fixed } from "../../styles/display";
import { _lightGreyText, lightgrey, darkgrey } from "../../styles/colours";

const Container = styled.div`
  background-color: ${darkgrey};
  ${fixed}
  top: 40px;
  width: 100vw;
  height: 100vh;
`;

const StyNavLink = styled.div`
  font-size: 1.1rem;
  height: 60px;
  color: ${lightgrey};
  ${_center}
  ${_float}
`;

const StyLink = styled(Link)`
  text-decoration: none;
  color: #eee;
`;

const NavItems = ({ toggleNav }) => (
  <Container>
    <ScrollLink
      onClick={toggleNav}
      activeClass="active"
      className="believe"
      to="We Believe"
      spy={true}
      smooth={true}
      duration={750}
    >
      <StyNavLink centertext={true}>We Believe</StyNavLink>
    </ScrollLink>
    <div>
      <StyLink to="/library">
        <StyNavLink>Library</StyNavLink>
      </StyLink>
    </div>
    <ScrollLink
      onClick={toggleNav}
      activeClass="active"
      className="test1"
      to="Our Practices"
      spy={true}
      smooth={true}
      duration={750}
    >
      <StyNavLink>Coaching Sectors</StyNavLink>
    </ScrollLink>
    <ScrollLink
      onClick={toggleNav}
      activeClass="active"
      className="test1"
      to="Our Coaches"
      spy={true}
      smooth={true}
      duration={750}
    >
      <StyNavLink>Meet the Team</StyNavLink>
    </ScrollLink>
    <div>
      <StyLink to="/our-ethics">
        <StyNavLink>Our Ethics</StyNavLink>
      </StyLink>
    </div>
    <div>
      <StyLink to="/contact-us">
        <StyNavLink>Contact Us</StyNavLink>
      </StyLink>
    </div>
  </Container>
);

export default NavItems;
