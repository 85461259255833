import React from 'react';
import styled from 'styled-components';

import {
  darkblue,
} from '../../styles/colours';

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const StyInput = styled.input`
  width: 100%;
  max-width: 300px;
  height: 30px;
  border-radius: 3px;
  border: 1px solid lightgrey;
  border-bottom: 1px solid ${darkblue};
  ${p => p.isActive && `border-bottom: 3px solid ${darkblue}`}
  box-shadow: inset 0 0 5px rgba(0,0,0,0.3);
`;

// border-bottom: 2px solid ${darkblue}
const Label = styled.p`
  font-size: 0.8rem;
  margin: 0;
`;

const Input = ({
  input,
  name,
  type,
  label,
  handleKeyPress,
  active,
  touched,
  error,
}) =>
  <InputContainer>
    <Label>{ label }</Label>
    <StyInput
      isActive={active}
      { ...input }
      onKeyPress={ handleKeyPress }
      name={ name }
      type={ type }
    />
  <div>{ !active && touched && error }</div>
  </InputContainer>;

export default Input;
