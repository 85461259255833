import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import styled from "styled-components";
import { _center, _float } from "../../styles/display";
import { _largeButton } from "../../styles/components";
import { _darkScheme } from "../../styles/colours";

const StyTileContainer = styled.div``;

const StyTile = styled.div`
  display: flex;
  flex-direction: row;
  height: 650px;

  @media (max-width: 1000px) {
    background: linear-gradient(
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.7)
      ),
      url("${(p) => p.imgLink}") no-repeat fixed center;
    background-size: cover;
  }
`;

const StyTileImage = styled.img`
  min-width: 650px;
  object-fit: cover;
  object-position: center;
  border-radius: 0 00px 00px 0;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const StyTileInfo = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const StyInfoText = styled.p`
  padding: 0 40px 0;
  margin-bottom: 40px;
`;

const StyInfoHeader = styled.h3`
  padding: 0 40px 0;
`;

const StySeparator = styled.div`
  height: 1px;
  background-color: #fff;
`;

const StyButton = styled(Link)`
  ${_largeButton}
  ${_darkScheme}
  ${_float}
  ${_center}
  margin-left: 40px;
`;

const Tiles = ({ tiles }) => {
  const renderTiles = (tiles) =>
    _.map(tiles, (tile, i) => (
      <Element name={tile.page} key={tile.page} id={tile.link}>
        <StyTile imgLink={tile.image}>
          <StyTileImage src={`${tile.image}`} alt={tile.page} reverse={i % 2} />
          <StyTileInfo>
            <StyInfoHeader>{tile.page}</StyInfoHeader>
            <StyInfoText>{tile.caption}</StyInfoText>
            <StyButton
              onClick={() => window.scrollTo(0, 0)}
              to={`/${tile.link}`}
            >
              Read more
            </StyButton>
          </StyTileInfo>
        </StyTile>
        <StySeparator />
      </Element>
    ));

  return <StyTileContainer>{renderTiles(tiles)}</StyTileContainer>;
};

export default Tiles;
