import React from 'react';
import PageWrapper from './PageWrapper';
import styled from 'styled-components';

import {
  _center,
} from '../styles/display';

import {
  darkblue
} from '../styles/colours';

import { HeaderBlockStyle } from '../styles/components';

const TextCol = styled.div`
  width: 95%;
  max-width: 700px;
  margin: 10px auto 10px;
`;

const WideBlock = styled.div`
  width: 95%;
  max-width: 900px;
  margin: 10px auto 75px;
`;

const HeaderBlock = styled.div`
  ${HeaderBlockStyle}
`;

const ListItem = styled.div`
  ${_center}
  width: 350px;
  text-decoration: none;
  border-bottom: 2px solid ${darkblue};
  margin-bottom: 50px;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 5px 10px rgba(30,30,30,0.4);
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
  font-style: italic;
`;

const Believe = () =>
  <PageWrapper backTo='we-believe'>
    <div>
      <HeaderBlock>
        <h1>We Believe</h1>
      </HeaderBlock>
      <TextCol>
        <p>
          At Damflask we believe in people. We believe that everyone is worthy of
          acceptance and belonging. In life it is easy to fall into patterns of
          thought, feelings and behaviour that are unhelpful to ourselves and
          others.
        </p>
      </TextCol>
      <TextCol>
        <p>
          Often a way of thinking that has proven helpful to date won’t
          take us where we want to be in the future. We believe that people and
          organisations can change for the better. We are here to help coachees
          clarify their thinking, be more aware of their impact on others and live
          more satisfying, fulfilling and productive lives.
        </p>
      </TextCol>
    </div>
    <div>
      <div>
        <HeaderBlock><h1>Our Principles</h1></HeaderBlock>
        <TextCol>
          <p>
            We are all about coaching – executive coaching, business coaching and
            life coaching, both on an individual basis and in teams. Our practice is
            based on the following principles:
          </p>
        </TextCol>
      </div>
      <WideBlock>
        <Flex>
          <ListItem>
            <p>
              We are here to facilitate your learning and help you clarify your
              thinking – it’s your agenda
            </p>
          </ListItem>
          <ListItem>
            <p>
              Everyone is doing the best they can under the circumstances that they
              find themselves, at all
              times
            </p>
          </ListItem>
          <ListItem>
            <p>
              Awareness is the starting point for growth and change
            </p>
          </ListItem>
          <ListItem>
            <p>
              We pay attention to the system around the individual, as this
              generates forces that will impact on your success
            </p>
          </ListItem>
        </Flex>
      </WideBlock>
    </div>
  </PageWrapper>;

export default Believe;
