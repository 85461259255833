import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';

import {
  _lightGrey
} from '../styles/colours';

const Div = styled.div`
  ${_lightGrey}
  background-image: url('/images/common/network.svg');
  background-size: cover;
  background-repeat: no-repeat;
  line-height: 1.75;
  overflow: auto;
`;

const Img = styled.img`
  position: absolute;
  top: 50px;
  left: 50px;
  height: 60px;
  transition: 0.3s ease-in-out;

  @media (max-width: 800px){
    top: 15px;
    left: 15px;
  }

  &:hover {
    transform: scale(1.1);
  }
`;

const PageWrapper = ({ children, backTo }) =>
  <Div>
    <Link to={`/#${backTo}`}><Img src='/images/common/back arrow.svg' /></Link>
    { children }
  </Div>;

export default PageWrapper;
