import React, { Component } from 'react';

import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';

class Nav extends Component {

  state = {
    windowWidth: window.innerWidth,
  };

  updateDimensions = () => this.setState({ windowWidth: window.innerWidth });

  componentDidMount () {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {

    const { windowWidth } = this.state;

    if (windowWidth > 1000) return <DesktopNav />;

    return <MobileNav />;

  }
}

export default Nav;
