import React from 'react';
import styled from 'styled-components';
import PageWrapper from './PageWrapper';
import { Link } from 'react-router-dom';

import { HeaderBlockStyle } from '../styles/components';

const Header = styled.div`
  ${HeaderBlockStyle}
`;

const TextCol = styled.div`
  width: 95%;
  max-width: 700px;
  margin: 10px auto 100px;
`;

const MentorCoaching = () =>
  <PageWrapper backTo='specialist-services'>
    <Header><h1>Mentor Coaching</h1></Header>
    <TextCol>
      <p>
        Mentoring can be the most efficient and rewarding pathway to staff development.
        Let us help you design and implement a mentoring programme in a way that ensures
        business impact and positive staff regard. Great mentoring programmes not only
        benefit staff performance and wellbeing directly but provide an opportunity to
        transform the culture of the organisation.
      </p>
      <p>
        We can help you think about the extent of the programme, set the right tone and
        design a process that works for you. By training both mentors and mentees,
        including 'in session' reflection, we put in place the interpersonal skills and
        mutual understanding of objectives and process that are essential.
      </p>
      <p>
        We maximise the impact of your mentoring programme by providing bespoke training
        to both mentors and mentees on:
      </p>
      <ul>
        <li>Structure</li>
        <li>Environment</li>
        <li>Attitude</li>
        <li>Listening skills</li>
        <li>Appreciation</li>
      </ul>
      <p>
        We explore the type of questions that open out a conversation, what we can
        do to establish equality of thinking between mentor and mentee and handling
        emotional release.
      </p>
      <p>
        Post-implementation, periodic feedback sessions ensure that the programme is
        sustaining its effectiveness.
      </p>
      <p>
        <Link to='/contact-us'>Contact us</Link> today for a free initial consultation.
      </p>
    </TextCol>
  </PageWrapper>;

export default MentorCoaching;
