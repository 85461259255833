import React from 'react';
import styled from 'styled-components';

import Fields from './Fields';
import { darkblue } from '../styles/colours';

const Button = styled.button`
  outline: none;
  background-color: #ddd;
  border: none;
  width: 120px;
  height: 50px;
  border-radius: 3px;
  border-bottom: 2px solid ${darkblue};
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    transform: scale(1.05)
  }
`;

const BasicForm = ({
  fields,
  handleSubmit,
  onSubmit,
  submit,
}) =>
    <div>
      <form onSubmit={ handleSubmit(onSubmit) } id='form'>
        <Fields fields={fields}/>
        <Button type="submit">{submit}</Button>
      </form>
    </div>;

export default BasicForm;
