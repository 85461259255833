import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PlainAnchor } from '../../styles/buttons';

const Container = styled.div`
  width: 30%;
  min-width: 300px;
  margin: 20px;
  background-color: white;
  box-shadow: 0 5px 15px -3px rgba(0,0,0,.3);

  @media (max-width: 1200px) {
    width: 45%;
  }

  @media (max-width: 800px) {
    width: 95%;
  }
`;

const Img = styled.div`
  width: 100%;
  height: 400px;
  display: block;
  margin: 0;
  background-image: url(${p => p.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 800px) {
    height: 300px;
  }
`;

const Cap = styled.i`
  font-size: 0.9rem;
  font-weight: 300;
  text-decoration: italic;
`;

const Title = styled.div`
  padding: 20px;
  box-sizing: border-box;
`;

const Blog = ({ blog }) =>
  <Container>
    <PlainAnchor href={blog.link} target='_blank' rel='noopener noreferrer'>
      <Img src={blog.img} />
      <Title>
        <h3>{blog.title}</h3>
        <Cap>{blog.caption}</Cap>
      </Title>
    </PlainAnchor>
  </Container>;


Blog.propTypes = {
  blog: PropTypes.object,
};

export default Blog;
