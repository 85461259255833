//jscs:disable maximumLineLength

import React from 'react';
import styled from 'styled-components';
import { S3 } from '../../constants/S3';

const Img = styled.img`
  float: left;
  margin-right: 30px;
  margin-bottom: 10px;
  width: 300px;

  @media (max-width: 1000px) {
    display: block;
    width: 90%;
    margin: 0 auto 20px;
    float: none;
  }
`;

const Education = () =>
  <div>
    <Img src={`${S3}034-small.jpg`} />
    <p>
      Workload, Targets, Appraisal, Observations, Behaviour Management, Ofsted…
    </p>
    <p>
      It is no surprise that teachers are leaving the profession at an alarming
      rate. Support and care for
      individuals in education is often reactive and therefore seldom successful.
    </p>
    <p>
      Evidence shows that proactive interventions have a significant impact on
      reducing staff absence and
      increasing productivity, motivation and success. We aim to deal with
      anxiety and stress as soon as it
      is recognised by the individual or their manager. By providing individuals,
      managers and executive
      leaders with one to one interventions and small group coaching we save
      you the cost of disruption,
      absence, recruitment and retraining.
    </p>
  </div>;

export default Education;
