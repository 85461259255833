import React from "react";
import styled from "styled-components";
import _ from "lodash";

import { lightgrey, darkgrey } from "../styles/colours";

const StyledCoachBio = styled.div`
  box-sizing: border-box;
  position: absolute;
  z-index: 100;
  white-space: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.8rem;
  line-height: 1.3;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  padding: 30px;
  transition: opacity 1s;
  opacity: 0;
  background-color: ${darkgrey};

  &:hover {
    opacity: 1;
  }

  @media (max-width: 500px) {
    padding: 10px;
    font-size: 0.8rem;
  }
`;

const CloseP = styled.p`
  margin: 10px 0 10px 0;
`;

const A = styled.a`
  color: ${lightgrey};
`;

const Social = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CoachBio = ({ coach, visible }) => (
  <StyledCoachBio visible={visible}>
    <div>
      {_.map(coach.bio, (p, i) => {
        if (typeof p === "string") {
          return <p key={i}>{p}</p>;
        } else {
          return (
            <ul>
              {_.map(p, (l, i) => (
                <li key={i}>{l}</li>
              ))}
            </ul>
          );
        }
      })}
      {coach.link && (
        <p>
          {coach.link.text}{" "}
          <A href={coach.link.link} target="_blank" rel="noopener noreferrer">
            {coach.link.linkText}
          </A>
        </p>
      )}
    </div>
    <div>
      <CloseP>e: {coach.email}</CloseP>
      <CloseP>t: {coach.phone}</CloseP>
      <Social>
        {coach.linkedin && (
          <CloseP>
            <A href={coach.linkedin} target="_blank" rel="noopener noreferrer">
              LinkedIn
            </A>
          </CloseP>
        )}
        {coach.insta && (
          <CloseP>
            <i className="fab fa-instagram" /> {coach.insta}
          </CloseP>
        )}
      </Social>
    </div>
  </StyledCoachBio>
);

export default CoachBio;
