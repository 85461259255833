import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(34,34,34,0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  ${p => p.onClick && `cursor: pointer;`}
  opacity: ${p => p.show ? 1 : 0};
  transition: opacity 0.75s;
`;

const Mask = ({ children, onclick, show }) =>
  <Container onClick={onclick} show={show}>
    { children }
  </Container>;

export default Mask;