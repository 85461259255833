import React from 'react';
import styled from 'styled-components';

import { S3 } from '../../constants/S3';

const Img = styled.img`
  float: right;
  margin-left: 15px;
  margin-bottom: 10px;
  width: 400px;

  @media (max-width: 1000px) {
    display: block;
    width: 90%;
    margin: 0 auto 20px;
    float: none;
  }
`;

const Business = () =>
  <div>
    <p>Do you recognise any of these in your business?</p>
    <ul>
      <li>
        Someone who was a high-achieving departmental head is struggling at board level
      </li>
      <li>
        A departmental head who produces strong financial results has behavioural flaws that
        impact on his team and/or people elsewhere in the organisation
      </li>
      <li>
        A high achieving top manager is looking for jobs with smaller businesses and lower pay
      </li>
      <li>
        The echelon of senior managers perform well as individuals but struggle to connect as a
        team
      </li>
    </ul>
    <p>
      <Img src={`${S3}010-small.jpg`} />
      These situations are not only familiar, but are eminently manageable and that the coaching
      interventions they require are a routine feature of the best businesses.
    </p>
    <p>
      We design and implement coaching interventions that support high performance in both leadership
      and team roles and help avoid burn out. In that way we save our clients the costs associated with
      suboptimal performance, sickness, recruitment and low morale.
    </p>
    <p>
      Our coaches come from a diversity of backgrounds including UK private industry – both blue chip
      and SME – multinationals, education and public service, which means that our practice is grounded
      in an understanding of organisational dynamics.
    </p>
  </div>;

export default Business;
