import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { darkblue, lightgrey, darkgrey } from "../../styles/colours";

const TabsNav = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 750px) {
    width: 95%;
  }
`;

const Tab = styled(NavLink)`
  box-sizing: border-box;
  display: block;
  width: 33%;
  min-width: 165px;
  height: 50px;
  text-align: center;
  background-color: white;
  border-radius: 5px 5px 0 0;
  border-right: 2px solid #bbb;
  text-decoration: none;
  color: ${darkgrey};
  transition: 0.3s;

  @media (max-width: 1000px) {
    width: 50%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const activeStyle = {
  backgroundColor: `${darkgrey}`,
  color: `${lightgrey}`,
};

const Tabs = () => (
  <TabsNav>
    <Tab activeStyle={activeStyle} to="/our-practices/executive-coaching">
      <p>Executive Coaching</p>
    </Tab>
    <Tab activeStyle={activeStyle} to="/our-practices/business-coaching">
      <p>Business Coaching</p>
    </Tab>
    <Tab activeStyle={activeStyle} to="/our-practices/public-service-coaching">
      <p>Public Service Coaching</p>
    </Tab>
  </TabsNav>
);

export default Tabs;
