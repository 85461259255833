import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { TAGS } from '../../constants/BlogData';
import { Flex } from '../../styles/blocks';
import { darkblue, darkgrey, lightgrey } from '../../styles/colours';

const Tag = styled.div`
  padding: 15px 30px 15px;
  box-sizing: border-box;
  margin: 30px;
  border-radius: 4px;
  transition: 0.3s;
  border: 1px solid ${darkgrey};
  ${p => p.active && `
    background-color: ${darkblue};
    border: 1px solid ${darkblue};
    color: ${lightgrey};
    font-weight: 800;
  `}
  cursor: pointer;

  @media (max-width: 600px) {
    margin: 5px;
  }
`;

const Tagfilters = ({ toggleTag, activeTags }) =>
  <Flex centreall fWrap>
    {
      _.map(
        TAGS,
        (tag, i) => <Tag key={i} onClick={() => toggleTag(tag.value)} active={activeTags.includes(tag.value)}>
          <span>{tag.label}</span>
        </Tag>
      )
    }
  </Flex>;

Tagfilters.propTypes = {
  toggleTag: PropTypes.func,
  activeTags: PropTypes.array,
};

export default Tagfilters;
