import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import { S3 } from "../../constants/S3";

import { _largeButton } from "../../styles/components";
import { darkblue, lightgrey, _darkScheme } from "../../styles/colours";
import { _center, _float } from "../../styles/display";

const Container = styled(Element)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 1000px) {
    height: 550px;
  }
`;

const FloatingHeaderContainer = styled.div`
  background: ${darkblue} url("/images/common/network.svg") no-repeat top center
    fixed;
  box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.7);
  color: ${lightgrey};
  width: 95%;
  max-width: 350px;
  text-align: center;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -175px;
  margin-top: -50px;
  z-index: 2;

  @media (max-width: 1000px) {
    top: -25px;
  }
`;

const Column = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 0;
  justify-content: space-between;
  text-align: center;
  padding: 30px 0 30px;

  @media (min-width: 425px) {
    padding: 30px;
  }

  @media (min-width: 575px) {
    min-width: 450px;
  }
`;

const BackgroundImage = styled.div`
  background: url("${S3}${(p) => (p.rev ? "043-small" : "039-small")}.jpg")
    no-repeat top center;
  background-size: cover;
  filter: opacity(0.3);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
`;

const Info = styled.p`
  line-height: 1.5;
  font-size: 1.075rem;
  box-sizing: border-box;
  width: 95%;
  ${(p) => (p.rev ? "padding-left" : "padding-right")}: 45%;
  text-align: ${(p) => (p.rev ? "right" : "left")};
`;

const StyButton = styled(Link)`
  ${_largeButton}
  ${_darkScheme}
  ${_float}
  ${_center}
`;

const SpecialistServices = () => (
  <Container name="Specialist Services" id="specialist-services">
    <Column>
      <BackgroundImage />
      <Content>
        <h1>Mentoring Consultancy and Training</h1>
        <Info>
          Mentoring can be the most efficient and rewarding pathway to staff
          development. Let us help you set up and monitor your programme so that
          it inspires and enthuses staff and mentor alike.
        </Info>
        <StyButton to="/mentor-coaching" onClick={() => window.scrollTo(0, 0)}>
          Read more
        </StyButton>
      </Content>
    </Column>
    <FloatingHeaderContainer>
      <h1>Specialist Services</h1>
    </FloatingHeaderContainer>
    <Column rev={true}>
      <BackgroundImage rev={true} />
      <Content>
        <h1>Coaching Retreats</h1>
        <Info rev={true}>
          Take your team away to the Derbyshire Dales for a long weekend of
          seclusion to take stock, find meaning and improve team cohesion.
        </Info>
        <StyButton
          to="/coaching-retreats"
          onClick={() => window.scrollTo(0, 0)}
        >
          Read more
        </StyButton>
      </Content>
    </Column>
  </Container>
);

export default SpecialistServices;
