import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Flex } from '../../styles/blocks';

import Blog from './Blog';

const Blogs = ({ blogs, tags }) =>
  <Flex fWrap centreall>
    {
      _.map(tags.length > 0 ? _.filter(blogs, (blog) => _.intersection(blog.tags, tags).length > 0) : blogs, (blog) =>
        <Blog key={blog.title} blog={blog} />
      )
    }
  </Flex>;

Blogs.propTypes = {
  blogs: PropTypes.array,
  tags: PropTypes.array,
};

export default Blogs;
