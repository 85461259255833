import React from 'react';
import styled from 'styled-components';

import {
  darkblue,
} from '../../styles/colours';

const StyInput = styled.textarea`
  width: 100%;
  border-radius: 3px;
  border: 1px solid lightgrey;
  border-bottom: 1px solid ${darkblue};
  ${p => p.isActive && `border-bottom: 2px solid ${darkblue}`}
  box-shadow: inset 0 0 5px rgba(0,0,0,0.3);
`;

const Radio = ({ input, name, label, meta:{ touched, error, active } }) =>
  <div>
    <div><label>{label}</label></div>
    <StyInput isActive={active} { ...input } name={ name } rows='8' />
    <div>{ !active && touched && error }</div>
  </div>;

export default Radio;
