import React from 'react';
import styled from 'styled-components';
import PageWrapper from './PageWrapper';

import { HeaderBlockStyle } from '../styles/components';

import {
  _center,
} from '../styles/display';

const Header = styled.div`
  ${HeaderBlockStyle}
`;

const TextCol = styled.div`
  width: 95%;
  max-width: 700px;
  margin: 10px auto 10px;
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px auto 30px;
  width: 95%;
  max-width: 800px;
  justify-content: space-around;
  font-style: italic;
`;

const ListItem = styled.div`
display: flex;
justify-content: flex-start;
align-items: top;
width: 350px;
max-width: 95%;
text-decoration: none;
margin-bottom: 30px;
padding: 10px;
border-radius: 5px;
`;

const Img = styled.img`
  max-height: 300px;
  max-width: 95%;
  display: block;
  margin-bottom: 50px;
`;

const Bullet = styled.img`
height: 25px;
margin: 3px 10px 0 0;
`;

const ListInfo = styled.p`
  margin: 0;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
`;

const Div = styled.div`
  max-width: 95%;
`;


const Ethics = () => {

  window.scrollTo(0,0);

  return (
    <PageWrapper backTo='our-ethics'>
      <Header><h1>Our Ethics</h1></Header>
      <FlexWrapper>
        <Div>
          <TextCol>
            <p>
              We abide by the Global Code of Ethics of the <a href='https://www.emccouncil.org/' rel='noopener noreferrer'>European Mentoring &amp;
              Coaching Council.</a> In particular our clients can expect from us:
            </p>
          </TextCol>
          <Flex>
            <ListItem>
              <Bullet src='/images/common/bullet.svg' />
              <ListInfo>
                Confidentiality
              </ListInfo>
            </ListItem>
            <ListItem>
              <Bullet src='/images/common/bullet.svg' />
              <ListInfo>
                An inclusive approach that embraces and explores individual differences
              </ListInfo>
            </ListItem>
            <ListItem>
              <Bullet src='/images/common/bullet.svg' />
              <ListInfo>
                A coach with the qualifications, skills and experience to meet your
                needs – if we don’t have this within our network then we will refer
                you on to a more appropriate coach or therapist
              </ListInfo>
            </ListItem>
            <ListItem>
              <Bullet src='/images/common/bullet.svg' />
              <ListInfo>
                Regular supervision of our coaches from an appropriately qualified supervisor
              </ListInfo>
            </ListItem>
          </Flex>
        </Div>
        <Img src='/images/home/ethics.jpg' />
      </FlexWrapper>
    </PageWrapper>
  );
}

export default Ethics;
