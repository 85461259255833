import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import PageWrapper from './PageWrapper';
import CoachInfo from '../constants/CoachInfo';
import CoachBio from './CoachBio';

import {
  _lightGreyText,
} from '../styles/colours';
import {
  HeaderBlockStyle,
} from '../styles/components';

const TilesWrapper = styled.div`
  box-sizing: border-box;
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px;
  ${_lightGreyText}

  @media (max-width: 500px) {
    padding: 0;
  }
`;

const Tile = styled.div`
  margin: 0 50px 0 50px;
  background-color: #222;
  width: 400px;
  box-shadow: 0px 10px 50px rgba(0,0,0,.4);
  transition: 0.5s;
  margin: 0 20px 30px;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 60px rgba(0,0,0,.6);
  }

  @media (max-width: 500px) {
    display: block;
    width: 95%;
  }
`;

const ProfilePhoto = styled.img`
  display: block;
  max-width: 100%;
  width: 100%;
`;

const TileInfo = styled.div`
  padding: 15px;
`;

const Center = styled.div`
  ${HeaderBlockStyle}
`;

class Coaches extends Component {

  state = {
    activeCoach: null,
  };

  setActiveCoach = (coach) => this.setState({ activeCoach: coach });

  renderCoaches = coaches => _.map(coaches, (coach, i) =>
    <Tile
      key={i}
      onClick={() => this.setActiveCoach(coach.photo)}
      onMouseEnter={() => this.setActiveCoach(coach.photo)}
      onMouseLeave={() => this.setActiveCoach(null)}
      >
      <ProfilePhoto src={`${coach.photo}`} alt={coach.name}/>
      <TileInfo>
        <h2>{ coach.name }</h2>
      </TileInfo>
      {
        this.state.activeCoach === coach.photo &&
        <CoachBio coach={coach} visible={this.state.activeCoach === coach.photo}/>
      }
    </Tile>
  );

  render () {
    return (
      <PageWrapper backTo='our-coaches'>
        <Center><h1>Our Coaches</h1></Center>
        <TilesWrapper>
          { this.renderCoaches(CoachInfo) }
        </TilesWrapper>
      </PageWrapper>
    );
  };
};

export default Coaches;
