import React from "react";
import PageWrapper from "../PageWrapper";
import styled from "styled-components";
import Testimonials from "../../constants/TestimonalData";
import { HeaderBlockStyle } from "../../styles/components";
import { darkblue } from "../../styles/colours";

// const Stone = styled.div`
//   width: 15px;
//   height: 15px;
//   border-radius: 50%;
//   background-color: ${p => (p.active ? "white" : darkblue)};
//   cursor: pointer;
//   border: ${p =>
//     p.active ? `3px solid ${darkblue}` : `3px solid transparent`};
//   transition: all 0.3s;
//   box-sizing: content-box;
// `;

// const Step = styled.div`
//   padding-top: 1px;
//   background-color: ${darkblue};
// `;

// const Card = styled.div`
//   padding: 10%;
//   border-radius: 5px;
//   background-color: rgba(255, 255, 255, 0.4);
//   box-shadow: 0 5px 10px rgba(30, 30, 30, 0.4);
//   position: absolute;
//   top: 50px;
//   left: 0;
// `;

// function Testimonal({ person, role, text, transitionState }) {
//   const defaultStyle = {
//     transition: `all 300ms ease-in-out`,
//     transform: `translateX(-30px)`,
//     opacity: 0
//   };

//   const transitionStyles = {
//     entering: {
//       opacity: 1,
//       transform: `translateX(0px)`,
//       transitionDelay: "300ms"
//     },
//     entered: {
//       opacity: 1,
//       transform: `translateX(0px)`,
//       transitionDelay: "300ms"
//     },
//     exiting: { opacity: 0 },
//     exited: { opacity: 0, transform: `translateX(30px)` }
//   };

//   const style = {
//     ...defaultStyle,
//     ...transitionStyles[transitionState]
//   };

//   return (
//     <Card style={style}>
//       <TextContainer>
//         <SpeechMark src="/images/sp_m.png" pos={1} />
//         {text.map((t, i) => (
//           <Text key={`text+${i}`}>{t}</Text>
//         ))}
//       </TextContainer>
//       <Name>{person}</Name>
//       <Role>{role}</Role>
//     </Card>
//   );
// }

// const CardControls = ({ card, setCard }) => (
//   <Flex key="cards" align="center" padding="10px">
//     {Testimonials.map((_, i) => [
//       <FlexChild width={1} key={"stone" + i}>
//         <Flex justify="center">
//           <Stone onClick={() => setCard(i)} active={i === card} />
//         </Flex>
//       </FlexChild>,
//       ...(i !== Testimonials.length - 1
//         ? [
//             <FlexChild width={1} key={"step" + i}>
//               <Step />
//             </FlexChild>
//           ]
//         : [])
//     ])}
//   </Flex>
// );

const HeaderBlock = styled.div`
  ${HeaderBlockStyle}
`;

const Body = styled.div`
  width: 95%;
  max-width: 800px;
  margin: 10px auto 10px;
`;

const CardContainer = styled.div`
  margin: 150px 0 150px;
  border-bottom: 3px solid ${darkblue};
`;

const Text = styled.p`
  font-style: italic;
  font-size: 1.1rem;
  text-align: center;
  line-height: 2;
  font-weight: 100;
  font-family: "Roboto Slab";

  @media (max-width: 500px) {
    font-size: 1rem;
  }
`;

const Subheading = styled.p`
  font-size: 1.1rem;
  text-align: center;
`;

const Role = styled.p`
  font-size: 0.75rem;
`;
const Name = styled.p`
  font-weight: 800;
`;

const TextContainer = styled.div`
  position: relative;
  padding: 3px 40px;
`;

const SpeechMark = styled.img`
  max-width: 30px;
  position: absolute;
  color: ${darkblue};
  ${p =>
    p.pos
      ? `
    top: 0;
    left: 0;
  `
      : `
    bottom: 0;
    right: 0;
    transform: rotate(180deg);
  `}
`;

function TestimonialFixed({ person, role, text }) {
  return (
    <CardContainer>
    <TextContainer>
        <SpeechMark src="/images/sp_m.png" pos={1} />
        <SpeechMark src="/images/sp_m.png" pos={0} />
        {text.map((t, i) => (
          <Text key={`text+${i}`}>{t}</Text>
        ))}
      </TextContainer>
      <Name>{person}</Name>
      <Role>{role}</Role>
    </CardContainer>
  );
}


export default function() {
  return (
    <PageWrapper backTo="testimonials">
      <HeaderBlock>
        <h1>Testimonials</h1>
      </HeaderBlock>

      <Body>
        <Subheading>
          We pride ourselves in our relationships with our clients and the
          results that we help them to achieve. Here are some thoughts that our
          previous clients have shared with us.
        </Subheading>

        {Testimonials.map((testimonial, index) => (
          <TestimonialFixed key={`${index}testimonial`} {...testimonial} />
        ))}
      </Body>
    </PageWrapper>
  );
}

{
  /* <CardControls card={card} setCard={setCard} />
        <CardContainer>
          {Testimonials.map((testimonial, index) => (
            <Transition
              in={card === index}
              timeout={300}
              key={"testimonialcard" + index}
            >
              {transitionState => (
                <Testimonal
                  key={`${index}testimonial`}
                  {...testimonial}
                  transitionState={transitionState}
                />
              )}
            </Transition>
          ))}
        </CardContainer> */
}
