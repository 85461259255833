import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Mask from '../Mask';
import {darkblue, lightgrey, _blueScheme, darkgrey, warning} from '../../styles/colours';
import {S3} from '../../constants/S3';
import {_largeButton} from '../../styles/components';

const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  height: 500px;
  display: flex;
  background: url(${S3}024-small.jpg) no-repeat center; 
  background-size: cover;
  position: relative;
  cursor: auto;
`;

const WrapperCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${darkgrey};
  opacity: 0.2;
`;

const Container = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const Left = styled.div`
  background-color: ${darkblue}88;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 40px;
  color: ${lightgrey};
`;

const Right = styled.div`
  flex: 1;
  @media(max-width: 700px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: 90%;
`;

const H = styled.h3`
  font-size: 1.5rem;
  margin: 30px 0 30px;
`;

const P = styled.p`
  width: 80%;
  text-align: center;
  line-height: 1.5;
  margin: 0;
`;

const EmailInput = styled.input`
  padding: 10px;
  font-size: 1.2rem;
  outline: none;
  border: 2px solid ${p => p.error ? warning : lightgrey};
  border-radius: 3px;
  text-align: center;
  color: ${lightgrey};
  background: transparent;
  margin: 20px 0 20px;
  width: 95%;
  max-width: 250px;

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${lightgrey};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${lightgrey};
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: ${lightgrey};
  }
`;

const Submit = styled.div`
  ${_largeButton}
  ${_blueScheme}
`;

const Error = styled.p`
  font-size: 0.8rem;
  color: ${warning};
`;

const Success = styled.div`
  padding: 0 30px 0;
  text-align: center;
`;

const Loader = styled.img`
  width: 150px;
  height: 150px;
`;

const Close = styled.div`
  background-color: transparent;
  color: ${warning};
  height: 30px;
  width: 30px;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  text-align: center;
  box-shadow: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 101;

  &:after {
    content: '✕';
  }
`;

class NewsletterModal extends React.Component {

  state = {
    email: '',
    success: false,
    error: '',
    touched: false,
    loading: false,
    show: false,
  };

  timeout = () => this.setState({show: true});

  componentDidMount = () => setTimeout(this.timeout, 10);
  componentWillUnmount = () => clearTimeout(this.timeout);

  onChange = (e) => this.setState({ email: e.target.value });

  onSubmit = () => {

    if (!re.test(this.state.email)) this.setState({ error: `Sorry, that doesn't appear to be a valid email address.` })
    else {
      this.setState({ loading: true });
      axios
        .post(
        '/api/add-to-newsletter',
        { email: this.state.email }
      )
        .then(() => this.setState({ success: true, loading: false }))
        .catch(({ response: { data }}) => {
          this.setState({ loading: false, error: data })
        });
    }
  }

  render () {

    const { toggle } = this.props;
    const { email, error, success, loading, show } = this.state;
    
    return (
      <Mask onclick={toggle} show={show}>
        <Wrapper onClick={(e)=>{ e.stopPropagation() }}>
          <WrapperCover />
          <Close onClick={toggle} />
          <Container>
            <Left>
              <Logo src={`/images/home/logo light.svg`} />
              <H>STAY CONNECTED</H>
              <P>
                Sign up to our quarterly newsletter to keep up to speed with the latest
                thinking on all things people management and find out what has been
                happening in our coaching and mentoring world
              </P>
              {
                success ?
                  <Success>
                    <H>Thank you for joining our network!</H>
                  </Success> :
                  loading ?
                  <Loader src='/images/common/loading.svg' /> :
                  <>
                    <EmailInput
                      type='text'
                      placeholder='Enter your email'
                      onBlur={() => this.setState({ touched: true })}
                      error={error}
                      onFocus={() => this.setState({ error: '' })}
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                    { error && <Error>{ error }</Error> }
                    <Submit onClick={this.onSubmit}>Submit</Submit>
                  </>
              }
            </Left>
            <Right>
            </Right>
          </Container>
        </Wrapper>
      </Mask>
    );
  }

}

export default NewsletterModal;