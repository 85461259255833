import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
import { Element } from 'react-scroll';

import { PracticeGridCaptions } from '../../constants/PracticesInfo';
import { _largeButton } from '../../styles/components';
import { _float, _center} from '../../styles/display';
import { _lightGreyText, _darkBlue, lightgrey, _darkScheme} from '../../styles/colours';

const Container = styled(Element)`
  margin: 50px 0 100px;
  position: relative;
  height: 1075px;
  width: 100%;
  overflow: hidden;
  ${_lightGreyText}

  @media (min-width: 800px){
    height: 700px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 100px 0 100px 0;
`;

const StyGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 50px auto 50px;
`;

const Header = styled.h2`
  text-align: center;
`;

const StyPractice = styled.div`
  width: 95%;
  height: 150px;
  max-width: 500px;
  transition: 0.5s;
  padding: 20px;
  box-sizing: border-box;
  ${_lightGreyText}
  display: flex;
  align-items: center;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 50px rgba(0,0,0,0.35);
  }
`;

const StyLink = styled(Link)`
  width: 40%;
  min-width: 320px;
  text-decoration: none;
  color: ${lightgrey};
  font-size: 1.5rem;
`;

const Icon = styled.img`
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  margin-right: 40px;
`;

const StyArc = styled.div`
  position: absolute;
  width: 200%;
  height: 550px;
  border-radius: ${p => p.reverse ? '0 0 50% 50%' : '50% 50% 0 0'};
  ${p => p.reverse ? 'bottom: 0' : 'top: 0'};
  left: -50%;
  z-index: 0;
  ${_darkBlue};
`;

const StyButton = styled(Link)`
  ${_largeButton}
  ${_darkScheme}
  ${_float}
  ${_center}
  margin: auto;
`;

const PracticeGrid = () =>
  <Container name='Our Practices' id='our-practices'>
    <StyArc />
    <StyArc reverse />
    <Overlay>
      <Header>Our Practices</Header>
      <StyGridContainer>
        {
          _.map(PracticeGridCaptions, (practice, i) =>
            <StyLink key={i} to={`/our-practices/${practice.link}`} onClick={() => window.scrollTo(0,0)}>
              <StyPractice key={i}>
                <Icon src={`/images/practices/${practice.icon}`} />
                <h3>{practice.name}</h3>
              </StyPractice>
            </StyLink>
          )
        }
      </StyGridContainer>
      <StyButton onClick={() => window.scrollTo(0, 0)} to='our-practices/executive-coaching'>
        Read more
      </StyButton>
    </Overlay>
  </Container>;

export default PracticeGrid;
