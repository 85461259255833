export const _lightGreyText = `
  color: #eee;
`;

export const lightgrey = `#eee`;
export const mediumgrey = `#999`;
export const darkgrey = `#222`;
export const darkblue = `#0076ab`;
export const warning = `#edadad`;

export const _darkGreyText = `
  color: #222;
`;

export const _lightGrey = `
  background-color: #eee;
`;

export const _darkGrey = `
  background-color: #111;
`;

export const _darkBlue = `
  background-color: #0076ab;
`;

export const _lightScheme = `
  ${_lightGreyText}
  border: 3px solid #eee;
  transition: 0.3s;

  &:hover {
    ${_darkGreyText}
    background-color: #eee;
  }
`;

export const _blueScheme = `
  ${_lightGreyText}
  border: 3px solid #eee;
  transition: 0.3s;
  background-color: ${darkblue}

  &:hover {
    ${_darkGreyText}
    background-color: #eee;
  }
`;

export const _darkScheme = `
  ${_lightGreyText}
  border: 3px solid #222;
  background-color: #222;
  transition: 0.3s;

  &:hover {
    ${_darkGreyText}
    background-color: #eee;
  }
`;
