export default [
  {
    person: "Rob Palmer",
    role: "Managing Director, Mascot Management",
    text: [
      `Chris is a true professional who is analytical and structured in his way of working. He is very good at
      locating the main drivers of a business and at the same time he is able to understand the important
      contexts within the business.`,
    ],
    caption: `
        [Chris] is very good at locating the main drivers of a business and at the same time he is able to
        understand the important contexts within the business.
    `,
  },
  {
    person: "Adrian Brown",
    role: "Owner and Chief Executive VP MED Group",
    text: [
      `Having worked with Nick as a coach and consultant now for almost eighteen months it is very
      positive to see the value a coach can add to our very dynamic business. Nick brings a sounding board
      and a safe place for our management team, where they can express concerns or thoughts in a very
      non-judgmental way. With a young and inexperienced team this has been an invaluable resource, as
      we build our business.`,
      `Nick’s business experience coupled with a patient and open approach has helped develop key team
      members through the critical period of first steps into a management roles, and in doing so, helped
      develop a good understanding for both the coachee and the organisation as to where support and
      development can be most beneficial.`,
    ],
    caption: `
      Nick’s business experience coupled with a patient and open approach has helped develop key team
      members through the critical period of first steps into a management roles.
    `,
  },
  {
    role: "HE lecturer and head of department",
    text: [
      `From the initial meeting, John made me feel completely at ease and his professionalism and diligent
      attention to ensuring that our sessions are conducted appropriately mean that I have the upmost
      trust and confidence in his integrity and levels of confidentiality.`,
      `John listens attentively and without judgement and this has allowed me to share my thoughts and
      feelings without fear of embarrassment or a desire to suppress, conceal or mask anything....we have
      uncovered elements of my personality and repeated behaviours (in both professional and personal
      life) that I was somewhat aware of but had never successfully addressed.`,
      `I feel very privileged to have had this experience.`,
    ],
    caption: `
      John listens attentively and without judgement and this has allowed me to share my thoughts and
      feelings without fear of embarrassment or a desire to suppress
    `,
  },
  {
    person: "Haydn Morris",
    role: "Co-Founder, Intique.net",
    text: [
      `Our sessions with Damflask really helped us to ensure that as co-founders we have a firm grasp on
      our short- and mid-term goals for the business. We were able to thoroughly assess the current state
      of the business and create actionable targets and goals in a way that would have been impossible
      without the experience and pragmatism that Chris brought to the session.`,
    ],
    caption: `
      Our sessions with Damflask really helped us to ensure that as co-founders we have a firm grasp on
      our short- and mid-term goals for the business.
    `,
  },
  {
    person: "Mike Colechin",
    role: "CEO, Cultivate Innovation",
    text: [
      `Faye’s input and counsel have been invaluable as I have worked through the challenges of setting
      up on my own.`,
    ],
    caption: `Faye’s input and counsel have been invaluable as I have worked through the challenges of setting
    up on my own.`,
  },
  {
    role: "Private client, London",
    text: [
      `Chris is a brilliant coach with great personal life experience. The benefits of working with him have
      been substantial in the short-term but I believe will also prove invaluable in the long-term.`,
      `He has helped me develop a more strategic approach to my career, improved my focus and enhanced my
      understanding and practice of leadership.`,
    ],
    caption: `
    Chris is a brilliant coach with great personal life experience. The benefits of working with him have
    been substantial in the short-term but I believe will also prove invaluable in the long-term.
    `,
  },
  {
    person: "Jessica Pugh",
    role: "Clinical Marketing Manager Creavo Medical Technologies",
    text: [
      `My sessions with Nick were my first experience of professional coaching. I was in a complicated
      work situation and I didn’t feel I had the experience or skills to manage it. Every session with Nick
      helped guide my own thinking about the situation and the techniques I could implement to try to
      improve the outcome.`,
      `Much of the time was spent helping to tease out my own ideas on how to handle things but when I
      was stuck, or frustrated Nick provided clear, unbiased and thoughtful observations and ideas. I
      probably had misconceptions about the level of benefit professional coaching could provide prior to
      beginning my sessions.`,
      `In actual fact the sessions empowered me and allowed me the clarity to
      solve the problems I faced in a style that I felt comfortable with. My sessions felt like a safe place to
      develop my ideas and plans with guidance where needed. I am incredibly grateful for the experience
      which was invaluable for me.`,
    ],
    caption: `
      Every session with Nick helped guide my own thinking about the situation and the techniques I could implement to try to improve the outcome.
    `,
  },
  {
    person: "Morwenna Foden",
    role: "Programme Lead, South Yorkshire Housing Association",
    text: [
      `Faye created an environment within which team members were able to explore how they could
       better influence those around them and how to maximise their impact within the business.`,
    ],
    caption: `Faye created an environment within which team members were able to explore how they
    could better influence those around them and how to maximise their impact within the business.`,
  },
  {
    person: "Marcus Orton",
    role: "CEO, SwabTech",
    text: [
      `Nick Walton has provided independent and informed insight that is hard to realise, even if one is
      practised in the art of self-reflection. It is useful to draw on the experience of the coach, even if not
      technically, directly applicable to the immediate context, the thought processes and prompt to a
      breadth of considerations that could be easily reduced under the duress of immediate demands and
      without a dispassionate third party.`,
      `This is a service I have benefited from, enjoyed and will use
      again. This has been made possible by the confident and engaging approach of the coach who has
      swiftly and effectively grasped the nature of the challenge and provided valuable feedback on how
      and what is being and addressed and how this might be achieved.`,
    ],
    caption: `Nick Walton has provided independent and informed insight that is hard to realise [...] This is a service I have benefited from, enjoyed and will use again.`,
  },
  {
    person: "William Wykes",
    role: "Marketing Manager Boston Scientific Cooperation",
    text: [
      `Whilst transitioning into an EMEA role for the first time in my medical devices career, I sought
      Nick’s counsel to make sure my start was impactful. Nick is a great listener. He helped me work
      through my plans to balance out any concerns and make a strong start.`,
      `Nick and I worked together at St Jude Medical and I knew of his extensive experience from a range
      of roles at a number of different Medical Device companies. Whilst he could tap into this experience
      during our coaching sessions, his great skill was getting me to do the thinking and define solutions. It
      really helped me at a time of career transition. I am all the better for it. Thank you, Nick.`,
    ],
    caption: `
    Whilst he could tap into this experience during our coaching sessions, his great skill was getting me
    to do the thinking and define solutions. It really helped me at a time of career transition.
    `,
  },
];
