import { darkblue } from './colours';
import { _center } from './display';

export const _largeButton = `
  text-align: center;
  display: block;
  width: 125px;
  padding: 15px;
  font-size: 1.25rem;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 10px 20px -8px rgba(0,0,0,0.5);

  &:link {
    text-decoration: none;
  }

`;

export const _DarkLink = `
  &:link {
    text-decoration: none;
  }

  &:visited {
    color: #222;
  }
`;

export const _LightLink = `
  &:link {
    text-decoration: none;
  }

  &:visited {
    color: #eee;
  }
`;

export const HeaderBlockStyle = `
  ${_center}
  text-align: center;
  padding: 75px 50px 0;
  width: 50%;
  max-width: 350px;
  margin: 0 auto 50px;
  border-bottom: 3px solid ${darkblue};
`;

// color: #eee;
// border: 3px solid #eee;
// &:hover {
//   color: #222;
//   background-color: #eee;
// }
