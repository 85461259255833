import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./services/Routes";
import { createGlobalStyle } from "styled-components";
import GAListener from "./GAListener";
import normalize from "normalize.css";
import { _darkGreyText } from "./styles/colours";
import NewsletterModal from "./components/newsletterModal/index";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Montserrat, Arial, Helvetica, sans-serif, Helvetica;
    ${_darkGreyText}
    box-sizing: border-box;
    }
`;

class Root extends Component {
  state = { modal: false };

  toggleModal = () => this.setState({ modal: !this.state.modal });

  render() {
    return (
      <BrowserRouter>
        <GAListener>
          <>
            <GlobalStyle />
            <Routes modal={this.state.modal} toggleModal={this.toggleModal} />
            {this.state.modal && <NewsletterModal toggle={this.toggleModal} />}
          </>
        </GAListener>
      </BrowserRouter>
    );
  }
}

export default Root;
