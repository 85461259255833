import React from 'react';
import styled from 'styled-components';
import PageWrapper from './PageWrapper';

import { HeaderBlockStyle } from '../styles/components';
import { S3 } from '../constants/S3';

const Header = styled.div`
  ${HeaderBlockStyle}
`;

const TextCol = styled.div`
  width: 95%;
  max-width: 700px;
  margin: 10px auto 100px;
`;

const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const UL = styled.ul`
  width: 350px;
`;
const Img = styled.img`
  height: 200px;
`;

const CoachingRetreats = () =>
  <PageWrapper backTo='specialist-services'>
    <Header><h1>Coaching Retreats</h1></Header>
    <TextCol>
      <p>
        Our coaching retreat for one to six people is located within the Peak District
        National Park on the edge of the Derbyshire Dales National Nature Reserve
        and 15 minutes’ drive from Chatsworth House. Here we can offer coachees
        a long weekend of seclusion to:
      </p>
      <Div>
        <UL>
          <li>Take stock on a personal or team level</li>
          <li>Find greater meaning, satisfaction and balance</li>
          <li>Improve team cohesion and dynamics</li>
          <li>Make an important business decision</li>
        </UL>
        <Img src={`${S3}040-small.jpg`}/>
      </Div>
      <p>
        For leaders it is an opportunity to get in touch with their personal values,
        how they relate to others, the organisation around them, their unique
        leadership potential and the potential of their organisation.
      </p>
      <p>
        The retreat is built around coaching, either one-to-one or team. We also
        offer mindfulness activities such as yoga and meditation and physical
        recreation in the form of walking, rock climbing, road biking and mountain biking.
      </p>
    </TextCol>
  </PageWrapper>;

export default CoachingRetreats;
