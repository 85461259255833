import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  _lightGreyText,
  darkgrey,
  lightgrey
} from '../styles/colours';

const Container = styled.div`
  position: relative;
  z-index: 0;
  background-color: ${darkgrey};
  ${_lightGreyText}
  font-size: 0.85rem;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1350px) {
    justify-content: space-around;
  }
`;

const StyLink = styled(Link)`
  display: block;
  ${_lightGreyText}
  margin: 10px 0 10px;
  text-decoration: none;
`;

const StyA = styled.a`
  display: block;
  ${_lightGreyText}
  margin: 10px 0 10px;
  text-decoration: none;
`;

const P = styled.p`
  cursor: pointer;
  margin: 10px 0 10px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const Col = styled.div`
  border-bottom: 2px solid ${lightgrey};
  width: 250px;
  margin-right: 20px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const StyLogo = styled.img`
  height: 45px;
  margin: 80px;
`;

const Footer = ({ showNewsletter }) =>
  <Container>
    <Row>
      <Col>
        <StyLink onClick={() => window.scrollTo(0, 0)}to='/we-believe'>Our Beliefs</StyLink>
        <StyLink onClick={() => window.scrollTo(0, 0)}to='/our-coaches'>Our Coaches</StyLink>
        <StyLink onClick={() => window.scrollTo(0, 0)}to='/library'>Library</StyLink>
        <StyLink onClick={() => window.scrollTo(0, 0)}to='/our-practices/executive-coaching'>Our Practices</StyLink>
        <StyLink onClick={() => window.scrollTo(0, 0)}to='/our-ethics'>Our Ethics</StyLink>
      </Col>
      <Col>
        <P onClick={showNewsletter}>Sign up to our quarterly newsletter</P>
        <StyLink onClick={() => window.scrollTo(0, 0)}to='/coaching-retreats'>Coaching Retreats</StyLink>
        <StyLink onClick={() => window.scrollTo(0, 0)}to='/mentor-coaching'>Mentor Coaching</StyLink>
        <StyLink onClick={() => window.scrollTo(0, 0)}to='/contact-us'>Contact us</StyLink>
        <StyA target='_blank' rel='noopener noreferrer' href='http://mentorsme.co.uk/organisations/damflask-consulting-ltd'>Mentorsme</StyA>
      </Col>
      <Col>
        <p>Tel: +447951 853 776</p>
        <p>Email: info@damflask-consulting.com</p>
        <hr />
        <p>104 Mona Road</p>
        <p>Sheffield</p>
        <p>S10 1NH</p>
      </Col>
    </Row>
    <StyLogo src='/images/home/logo light.svg' />
  </Container>;

export default Footer;
