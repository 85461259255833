const BUSINESS = "BUSINESS";
const MANAGEMENT = "MANAGEMENT";
const MENTORING = "MENTORING";
const LIFE = "LIFE";
const STARTUP = "STARTUP";
const LEADERSHIP = "LEADERSHIP";

export const TAGS = [
  { label: "Business", value: BUSINESS },
  { label: "Management", value: MANAGEMENT },
  { label: "Coaching & Mentoring", value: MENTORING },
  { label: "Startups", value: STARTUP },
  { label: "Leadership", value: LEADERSHIP },
  { label: "Life", value: LIFE },
];
// {
//   title: '',
//   link: '',
//   img: '',
//   caption: '',
//   date: '',
//   tags: [BUSINESS]
// },

export const DATA = [
  {
    title: "Setting Goals and Follow Up (Performance Management 2/2)",
    link:
      "https://medium.com/@chris_97488/3-things-that-all-hr-professionals-should-know-5c6635de857c",
    img: "https://miro.medium.com/max/1400/1*xbadCWAbFzcGpyvIjuwidg.jpeg",
    caption: "The second half of an article on managing performance.",
    date: "Oct 13, 2020",
    tags: [LEADERSHIP, MANAGEMENT],
  },
  {
    title: "Equipped to Succeed (Performance Management Part 1/2)",
    link:
      "https://medium.com/the-innovation/equipped-to-succeed-performance-management-part-1-2-64866e6df375",
    img: "https://miro.medium.com/max/1400/1*xbadCWAbFzcGpyvIjuwidg.jpeg",
    caption:
      "If you’re new to team leadership and aspire to a high-performing team of fulfilled individuals (including yourself) then read on.",
    date: "Oct 13, 2020",
    tags: [LEADERSHIP, MANAGEMENT],
  },
  {
    title:
      "Creating a compassionate subculture under pressurised circumstances",
    link:
      "https://medium.com/@chris_97488/creating-a-compassionate-subculture-under-pressurised-circumstances-f265fd96e2e",
    img: "https://miro.medium.com/max/1400/1*t2wgo3m3F2GQXpq86opmng.jpeg",
    caption: "A long title for a short piece of reflection",
    date: "Jan 4, 2020",
    tags: [MANAGEMENT, BUSINESS],
  },
  {
    title: "3 Things That All HR Professionals Should Know",
    link:
      "https://medium.com/@chris_97488/3-things-that-all-hr-professionals-should-know-5c6635de857c",
    img: "https://miro.medium.com/max/4242/1*D3uDGUNJhEQkHJtewGP0pg.jpeg",
    caption:
      "The Peter Prinicple, Snakes in Suits, and how culture eats strategy for breakfast",
    date: "Sep 9, 2019",
    tags: [LEADERSHIP, MANAGEMENT],
  },
  {
    title: "Two’s Company",
    link: "https://medium.com/@chris_97488/twos-company-9d9c1f9985be",
    img: "https://miro.medium.com/max/4242/1*vykkgH_M9p1o0CGuk4sJWA.jpeg",
    caption:
      "Down the years some of the most successful businesses have been started and run by two individuals with complementary skills.",
    date: "Aug 28, 2019",
    tags: [BUSINESS],
  },
  {
    title: "Starting a New Job",
    link: "https://medium.com/@chris_97488/starting-a-new-job-1e2df904cb1b",
    img: "https://miro.medium.com/max/10952/1*58q9afqHAR4iMWZGlXgx9Q.jpeg",
    caption: "You can’t learn all you need to know from the company accounts.",
    date: "Jul 4, 2019",
    tags: [LIFE],
  },
  {
    title: "How to Be Topp*",
    link: "https://medium.com/@chris_97488/how-to-be-topp-410030b3cd4e",
    img: "https://miro.medium.com/max/4238/1*wslYXYkh-cWjUgQN99hbCA.jpeg",
    caption:
      "I recently found myself mentoring someone who wants to make it to the very top of her profession. This letter is based on our discussions concerning how you might make it to the top.",
    date: "Jun 27, 2019",
    tags: [MENTORING, LIFE],
  },
  {
    title: "Blessed are the Peacemakers…",
    link:
      "https://medium.com/@chris_97488/blessed-are-the-peacemakers-4be59c9686b6",
    img: "https://miro.medium.com/max/4238/1*Dn-1VHfJfZvEtydNo4xcRQ.jpeg",
    caption:
      "Why developing the ability to understand an opposing viewpoint is important, and how we might go about it.",
    date: "Jun 10, 2019",
    tags: [MANAGEMENT, LEADERSHIP],
  },
  {
    title: "Trusting The Top Team",
    link: "https://medium.com/@chris_97488/trusting-the-top-team-af317fefa057",
    img:
      "https://cdn-images-1.medium.com/max/1000/1*Jc1Fdn6o6NYHQL-hZP5JxQ.jpeg",
    caption: "“To whom much is given, much is required.”",
    date: "May 10, 2019",
    tags: [BUSINESS, LEADERSHIP],
  },
  {
    title: "Perfectionism and People Pleasing",
    link:
      "https://medium.com/@chris_97488/perfectionism-and-people-pleasing-d75a6ecf7519",
    img:
      "https://cdn-images-1.medium.com/max/1000/1*1j2yoDD5mlIv1pJ8gvjAJQ.jpeg",
    caption:
      "The perfect storm. Combine these comparatively innocuous traits in one person and you get a multiplier effect...",
    date: "May 4, 2019",
    tags: [LIFE, BUSINESS],
  },
  {
    title: "Personal Effectiveness in 3 Diagrams",
    link:
      "https://medium.com/@chris_97488/personal-effectiveness-in-3-diagrams-66e834e692cd",
    img:
      "https://cdn-images-1.medium.com/max/1600/1*jr7Ra2G97psqlgIAcAjJEA.jpeg",
    caption:
      "Given that whole books have been written on personal effectiveness, 3 diagrams may sound a tall order, but bear with me and see what you think.",
    date: "Apr 22, 2019",
    tags: [BUSINESS],
  },
  {
    title: "“Sorry, did you mean to delegate that?”",
    link:
      "https://medium.com/@chris_97488/sorry-did-you-mean-to-delegate-that-4d490e053cc2",
    img:
      "https://cdn-images-1.medium.com/max/1600/1*4bpcEZF3WmEzmxQAuEk8xw.jpeg",
    caption:
      "Delegation, it can’t be that difficult. I mean it’s hardly a skill at all!",
    date: "Apr 15, 2019",
    tags: [LEADERSHIP, MANAGEMENT],
  },
  {
    title: "“You’re the worst colleague we have.”",
    link:
      "https://medium.com/@chris_97488/youre-the-worst-colleague-we-have-84363d33d0a",
    img:
      "https://cdn-images-1.medium.com/max/1000/1*ZsGD2W1q5kyVnqUkj-hB4w.jpeg",
    caption: "Is there hope for a more compassionate workplace?",
    date: "Apr 11, 2019",
    tags: [MANAGEMENT, LEADERSHIP],
  },
  {
    title: "The Life-Giving Workforce Design Model",
    link:
      "https://medium.com/@chris_97488/the-life-giving-workforce-design-model-b798ad36a23",
    img:
      "https://cdn-images-1.medium.com/max/1000/1*l6tyrUa_iDg8CK3ZAIzEmg.jpeg",
    caption: "A thought-provoking piece of work by Barry J Halm.",
    date: "Mar 4, 2019",
    tags: [BUSINESS, MANAGEMENT, LIFE],
  },
  {
    title: "People, Product and Profit",
    link:
      "https://medium.com/@chris_97488/people-product-and-profit-20b365fb8f16",
    img:
      "https://cdn-images-1.medium.com/max/1000/1*KqrQaagFYgRNEX-96z08Nw.jpeg",
    caption: "Profit is a goal that should be approached obliquely.",
    date: "Feb 27, 2019",
    tags: [BUSINESS],
  },
  {
    title: "The Duke Ellington Principle",
    link:
      "https://medium.com/@chris_97488/the-duke-ellington-principle-a5d35af48fce",
    img:
      "https://cdn-images-1.medium.com/max/1000/1*ZJKr05V3cdG8l7RNT4KuZQ.jpeg",
    caption:
      "Two recruitment questions for bandleaders and business leaders alike",
    date: "Feb 21, 2019",
    tags: [MANAGEMENT],
  },
  {
    title: "How big does the elephant have to get?",
    link:
      "https://medium.com/@chris_97488/how-big-does-the-elephant-have-to-get-56ef3506b4a5",
    img:
      "https://cdn-images-1.medium.com/max/1000/1*39GuQ5RUTQZO3mya-CSXGw.jpeg",
    caption:
      "Applying the ideas within Immunity to Change by Kegan & Lacey on a national scale.",
    date: "Feb 11, 2019",
    tags: [LEADERSHIP],
  },
  {
    title: "Internal Mentoring – How Hard Can it Be?",
    link:
      "https://www.myhrtoolkit.com/2018/11/internal-mentoring-how-hard-can-it-be/",
    img: "https://www.myhrtoolkit.com/hubfs/internal-mentoring.jpg",
    caption: "The importance of planning and training in mentoring schemes.",
    date: "",
    tags: [MENTORING],
  },
  {
    title: "Why haven’t more people heard about this psychological concept?",
    link:
      "https://medium.com/@chris_97488/why-havent-more-people-heard-about-this-psychological-concept-943b64c34ca2",
    img:
      "https://cdn-images-1.medium.com/max/1000/1*AfS5LcjAinaXEUYHZV2Z5w.jpeg",
    caption:
      "An item on transference, termed the ‘repetition compulsion’ by Freud.",
    date: "Feb 11, 2019",
    tags: [MANAGEMENT],
  },
  {
    title: "Finding your vocation – a heretical perspective",
    link:
      "https://medium.com/@chris_97488/finding-your-vocation-a-heretical-perspective-ecd00fe79948",
    img:
      "https://cdn-images-1.medium.com/max/1000/1*dVCSMJppxIhXNttcGfJvCQ.jpeg",
    caption: "Some thinking about finding the right job for you.",
    date: "Feb 4, 2019",
    tags: [LIFE],
  },
  {
    title: "Intique.net - coaching an internet startup",
    img: "/images/blog/intique.jpeg",
    link:
      "https://www.linkedin.com/pulse/intiquenet-coaching-internet-startup-chris-whitehead/",
    caption: "Some issues that arise in an internet startup.",
    date: "Jan 25, 2019",
    tags: [MENTORING, STARTUP],
  },
  {
    title: "In Xanadu",
    link: "https://www.linkedin.com/pulse/xanadu-chris-whitehead/",
    img: "/images/blog/inXandu.jpeg",
    caption: "Creating a personal development plan with a difference.",
    date: "Jan 24, 2019",
    tags: [LIFE],
  },
  {
    title: "An appraisal by any other name…",
    link:
      "https://www.linkedin.com/pulse/appraisal-any-other-name-chris-whitehead/",
    img: "/images/blog/appraisal.jpeg",
    caption:
      "Three questions for the manager to ask themselves in advance of an appraisal.",
    date: "Dec 17, 2018",
    tags: [MANAGEMENT],
  },
];
