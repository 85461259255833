import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
  position fixed;
  top: 100px;
  left: 50%;
  margin: 0 0 0 -45%;
  width: 80%;
  padding: 50px;
  max-width: 800px;
  background-color: ${p => p.response === 'success' ? '#c6c9ff' : '#ffc6cb'}
  box-shadow: 0 5px 10px rgba(0,0,0,0.5);
`;

const Button = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
`;

const Message = ({ message, response, close }) =>
  <Box response={response}>
    <Button src='/images/common/close.svg' onClick={close} />
    {message}
  </Box>;

export default Message;
