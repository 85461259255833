import React, { Component } from 'react';
import styled from 'styled-components';

import NavItems from './NavItems';
import { darkgrey } from '../../styles/colours';
import { fixed } from '../../styles/display';

const NavTop = styled.div`
  width: 100vw;
  background-color: ${darkgrey};
  padding: 15px;
  ${fixed}
`;

const Img = styled.img`
  height: 20px;
`;

class MobileNav extends Component {

  state = {
    isOpen: false,
  };

  toggleNav = () => this.setState({ isOpen: !this.state.isOpen });

  render() {

    const { isOpen } = this.state;

    return (
      <div>
        <NavTop>
          <Img src='/images/home/nav-button.svg' onClick={this.toggleNav} />
        </NavTop>
        {
          isOpen &&
          <div>
            <NavItems toggleNav={this.toggleNav}/>
          </div>
        }
      </div>
    );
  }
}

export default MobileNav;
