import React from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import PageWrapper from './PageWrapper';

import Tabs from './practices/Tabs';

import Business from './practices/Business';
import Civil from './practices/Civil';
import Education from './practices/Education';
import Executive from './practices/Executive';

import { HeaderBlockStyle } from '../styles/components';

const HeaderBlock = styled.div`
  ${HeaderBlockStyle}
`;

const Container = styled.div`
  margin-bottom: 40px;
`;

const PracticeInfo = styled.div`
  box-sizing: border-box;
  width: 80%;
  margin: 0 auto;
  background-color: rgba(255,255,255, 0.5);
  padding: 20px 100px;

  @media (max-width: 1000px) {
    padding: 20px 50px;
  }

  @media (max-width: 750px) {
    width: 95%;
  }
`;

const Practices = () => {
  return(
    <PageWrapper backTo='our-practices'>
      <HeaderBlock>
        <h1>Our Practices</h1>
      </HeaderBlock>
      <Container>
        <Tabs />
        <Switch>
          <PracticeInfo>
            <Route path='/our-practices/executive-coaching' component={Executive} />
            <Route path='/our-practices/education-coaching' component={Education} />
            <Route path='/our-practices/business-coaching' component={Business} />
            <Route path='/our-practices/public-service-coaching' component={Civil} />
          </PracticeInfo>
        </Switch>
      </Container>
    </PageWrapper>
  );
}

export default Practices;
