import styled from 'styled-components';
import {Link} from 'react-router-dom';
import * as colours from './colours';



export const PlainAnchor = styled.a`
  color: ${colours.darkgrey};
  &:link {
    text-decoration: none;
  }
`;
/*
*
* NOTE: LARGE BUTTONS
*/



export const PlainLink = styled(Link)`
  text-decoration: none;
  color: ${p => p.colour ? p.colour : colours.darkgrey};
  ${p => p.w && `width: ${p.w}%;`}
  ${p => p.stretch && `width: 100%;`}
  ${p => p.flexgrow && `flex: 1;`}
  ${p => p.margin && `margin: ${p.margin};`}
  ${p => p.centretext && `text-align: center;`}
  ${p => p.resp === 'mob' && `
    @media(min-width: 500px){
      display: none;
    }
  `}
  ${p => p.resp === 'desk' && `
    @media(max-width: 500px){
      display: none;
    }
  `}
`;
