import styled from 'styled-components';
import * as colours from './colours';
/*
*
* NOTE: BLOCKS
*/
export const PANEL = `
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px 5px rgba(0,0,0,0.2);
`;

export const Flex = styled.div`
  display: flex;
  ${p => p.stretch && 'width: 100%;'}
  ${p => p.h && `height: ${p.h}px`}
  ${p => p.w && `max-width: ${p.w}px`}
  ${p => p.margin && `margin: ${p.margin}`}
  ${p => p.padding && `padding: ${p.padding}`}
  ${p => p.align && 'align-items: center;'}
  ${p => p.fgrow && `flex: ${p.fgrow};`}
  flex-wrap: ${p => p.fWrap ? 'wrap' : 'nowrap'};
  flex-direction: ${p => p.col ? 'column' : 'row'};
  justify-content: ${p => p.justify ? p.justify : 'flex-start'};
  ${p => p.responsive && `
    @media(max-width: 700px) {
      display: block;
    }
  `}
  ${p => p.centreall && `
    justify-content: center;
    align-items: center;
  `}
`;

export const FlexChild = styled.div`
  flex: ${p => p.flex ? p.flex : 1};
  margin: ${p => p.m ? p.m : '0'};
  ${p => p.minWidth && `min-width: ${p.minWidth};`}
  ${p => p.width && `width: ${p.width};`}
  ${p => p.border &&`
    border-radius: 4px;
    box-shadow: 0 10px 20px -6px rgba(0,0,0,.5);
    padding: 20px;
    box-sizing: border-box;
  `}
`;

export const HoverSpan = styled.span`
  font-weight: 300;
  display: inline;
  position: relative;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const HoverBox = styled.span`
  display: block;
  position: absolute;
  top: 30px;
  right: 0;
  text-transform: none;
  font-size: 0.9rem;
  line-height: 1.25;
  color: ${colours.darkgrey};
  background-color: ${colours.lightgrey};
  padding: 10px;
  border-radius: 4px;
  width: 200px;
  z-index: 1;
  box-shadow: 0 4px 20px -2px rgba(0,0,0,0.5);
`;

export const Divider = styled.div`
  width: 95%;
  min-width: 95%;
  border-bottom: 1px solid ${colours.mediumgrey};
  margin: 10px 0 10px;
`;

export const GapDivider = styled.div`
  margin: 15px 0 15px;
  height: 15px;
  background-color: ${colours.lightgrey};
  box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
`;

export const HeaderBlock = styled.div`
  font-family: Montserrat;
  text-align: center;
  font-weight: 300;
  font-size: ${p =>
    p.size === 3 ? '1.5' :
      p.size === 2 ? '1.2' :
        p.size === 1 && '1' }rem;
  padding: 30px;
  box-sizing: border-box;
`;

export const InfoPara = styled.p`
  font-size: 0.9rem;
  line-height: 2;
`;
