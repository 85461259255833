import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import PageWrapper from './PageWrapper';

const Center = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 500px;
  width: 95%;
  max-width: 600px;
  margin: 0 auto 100px;
  padding-top: 100px;
`;

const NotFound = () =>
  <PageWrapper>
    <Center>
      <h1>Oops! We couldn't find that page!</h1>
      <h2>
        <Link to='/'>Click here</Link> to go back to the homepage,
        or <Link to='/contact-us'>click here</Link> to send us a message if you
        can't find what you're looking for!
        </h2>
    </Center>
  </PageWrapper>;

export default NotFound;
