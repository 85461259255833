export const _center = `
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const _float = `
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
`;

export const fixed = `
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
`;
